body {
  margin: 0;
}
.App {
  font-family: sans-serif;
  text-align: center;
}

a {
  color: black;
  font-weight: bold;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active {
  text-decoration: underline;
}

li {
  list-style-type: none;
}

canvas {
  margin: 0;
}
